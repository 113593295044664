import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "newsletterSignup" }
const _hoisted_2 = { id: "signupFormWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("h4", null, "Subscribe To Our Newsletter", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        id: "email",
        type: "email",
        name: "email",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
        autocomplete: "email",
        placeholder: "email",
        required: ""
      }, null, 512 /* NEED_PATCH */), [
        [_vModelText, $setup.email]
      ]),
      _createVNode($setup["Button_Medium"], {
        onClick: $setup.signup,
        disabled: !$setup.email,
        class: _normalizeClass({disabled: !$setup.email})
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode("Submit")
        ])),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "class"])
    ])
  ]))
}