import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "item-details-container" }
const _hoisted_2 = {
  key: 0,
  class: "itemview-infobox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gbModal = _resolveComponent("gbModal")!

  return (_openBlock(), _createBlock(_component_gbModal, {
    name: "RecipeBuilder_Item_Details",
    height: "100%",
    adaptive: "",
    onBeforeOpen: $setup.beforeOpen
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode($setup["ItemView_ItemContainer"], null, {
          default: _withCtx(() => [
            _createVNode($setup["ItemView_ImageBox"], {
              photos: $setup.item?.photos
            }, null, 8 /* PROPS */, ["photos"]),
            ($setup.item)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode($setup["ItemView_ItemName"], {
                    name: $setup.item.name
                  }, null, 8 /* PROPS */, ["name"]),
                  _createVNode($setup["ItemView_DescriptionShort"], {
                    description_short: $setup.item.description_short
                  }, null, 8 /* PROPS */, ["description_short"]),
                  _createVNode($setup["ItemView_DescriptionLong"], {
                    description_long: $setup.item.description_long
                  }, null, 8 /* PROPS */, ["description_long"])
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["ButtonBar_Center"], null, {
          default: _withCtx(() => [
            _createVNode($setup["Button_Medium"], {
              onClick: _withModifiers($setup.close, ["stop","prevent"])
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Close")
              ])),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }))
}