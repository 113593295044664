import { defineComponent as _defineComponent } from 'vue'
import type { IPublicInventoryItem } from "types/InventoryItem"


export default /*@__PURE__*/_defineComponent({
  __name: 'ItemView_ItemName',
  props: {
    name: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();



const __returned__ = {  }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})