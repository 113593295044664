import type { App } from "vue"
import { ref } from "vue"
import gbDialog from "./gbDialog.vue"

interface IGBDialogOptions {
	isOpen: boolean
	title: string
	message: string
	showButton: boolean
	buttonText: string
}

export default {
	install: (app: App): void => {
		app.component("gbDialog", gbDialog)
	},
}

const dialogOptions = ref<IGBDialogOptions>({
	isOpen: false,
	title: "",
	message: "",
	showButton: true,
	buttonText: "OK",
})

export const useDialog = () => {
	const showDialog = (
		title: IGBDialogOptions["title"],
		message: IGBDialogOptions["message"] = "",
	): void => {
		dialogOptions.value.isOpen = true
		dialogOptions.value.title = title
		dialogOptions.value.message = message
	}

	const closeDialog = (): void => {
		dialogOptions.value.isOpen = false
	}

	return {
		showDialog,
		closeDialog,
		dialogOptions,
	}
}
