import type { IPublicInventoryItem } from "types/InventoryItem"
import type { IRecipe } from "types/Recipe"
import type { IShopCartItem } from "types/ShopCartItem"
import type { IShopCategory } from "types/ShopCategory"
import type { EcommerceAction } from "vue-gtag"

export const getAnalyticsFormatItems = (
	cart: IShopCartItem[],
	recipes: IRecipe[],
	inventoryItems: IPublicInventoryItem[],
	shopCategories: IShopCategory[],
): EcommerceAction["items"] => {
	const formatedItems: EcommerceAction["items"] = []
	cart.forEach((item) => {
		const inventoryItem = getInventoryItemBySKU(item.sku, inventoryItems)
		if (inventoryItem === null) return
		const canonical_category = getShopCategoryByID(
			inventoryItem.canonical_category,
			shopCategories,
		)
		if (canonical_category === null) return
		const itemParams = {
			item_id: inventoryItem.sku,
			item_name: inventoryItem.receipt_name,
			item_category: canonical_category.name,
			location_id: "ChIJr1Am8ZnRD4gRN6sQmKfcsuU",
			price: inventoryItem.price,
			quantity: item.quantity,
		}
		formatedItems.push(itemParams)
	})
	recipes.forEach((recipe) => {
		recipe.items.forEach((item) => {
			const inventoryItem = getInventoryItemBySKU(
				item.sku,
				inventoryItems,
			)
			if (inventoryItem === null) return
			const canonical_category = getShopCategoryByID(
				inventoryItem.canonical_category,
				shopCategories,
			)
			if (canonical_category === null) return
			const itemParams = {
				item_id: inventoryItem.sku,
				item_name: inventoryItem.receipt_name,
				item_category: canonical_category.name,
				location_id: "ChIJr1Am8ZnRD4gRN6sQmKfcsuU",
				price: inventoryItem.price,
				quantity: item.quantity,
			}
			formatedItems.push(itemParams)
		})
	})
	return formatedItems
}

const getInventoryItemBySKU = (
	sku: number,
	inventoryItems: IPublicInventoryItem[],
) => {
	return inventoryItems.find((item) => item.sku === sku) || null
}
const getShopCategoryByID = (
	categoryID: number,
	shopCategories: IShopCategory[],
): IShopCategory | null => {
	return shopCategories.find((category) => category.id === categoryID) || null
}
