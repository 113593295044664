import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { useRecipeBuilderStore } from "@website/stores/recipeBuilder"
import { storeToRefs } from "pinia"
import { onMounted, ref } from "vue"
import RecipeCart from "./recipeCart.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeCart_Container',
  emits: ["closeHeaderMenu"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit
const recipeBuilderStore = useRecipeBuilderStore()
const { itemCount } = storeToRefs(recipeBuilderStore)

const isCartOpen = ref(false)
const clickListenerActive = ref(false)

const toggleRecipeCartOpen = (): void => {
	if (isCartOpen.value === true) closeRecipeCart()
	else if (isCartOpen.value === false) openHeaderCart()
}
const openHeaderCart = (): void => {
	isCartOpen.value = true
	emit("closeHeaderMenu")
	if (!clickListenerActive.value) {
		window.addEventListener("click", checkForClickToClose)
		clickListenerActive.value = true
	}
}
const closeRecipeCart = (): void => {
	isCartOpen.value = false
	window.removeEventListener("click", checkForClickToClose)
	clickListenerActive.value = false
}

const checkForClickToClose = (event: MouseEvent): void => {
	if (clickListenerActive.value === false) return
	const detectionArea = document.getElementById("RecipeBuilderHeader")
	if (
		detectionArea &&
		event.target instanceof Node &&
		detectionArea.contains(event.target) === false
	) {
		closeRecipeCart()
	}
}
const checkForSwipeToClose = (
	event: TouchEvent,
	touchStartY: number,
	screenStartY: number,
): void => {
	const touchEndY = event.changedTouches[0].clientY
	const screenEndY = window.screenY
	if (screenStartY === screenEndY && touchEndY > touchStartY) {
		const screenHeight = window.innerHeight
		const touchMovement = touchEndY - touchStartY
		const percentOfScreen = touchMovement / screenHeight
		//5.7.2024 - changed from 0.25 to 0.22
		if (percentOfScreen > 0.22) {
			$log.info("[RecipeBuilder_Cart] Cart Pulled Down > .22 - Closed", {
				screenHeight,
				touchMovement,
				percentOfScreen,
			})
			closeRecipeCart()
		} else {
			$log.info(
				"[RecipeBuilder_Cart] Cart Pulled Down < 0.22 - Not Closed",
				{ screenHeight, touchMovement, percentOfScreen },
			)
		}
	}
}
onMounted(() => {
	//Detect when user swipes down on cart
	const headerCartWrapper = document.getElementById(
		"RecipeHeader-cart-wrapper",
	)
	headerCartWrapper?.addEventListener(
		"touchstart",
		(event) => {
			const touchStartY = event.touches[0].clientY
			const screenStartY = window.screenY
			const touchEndHandler = (event: TouchEvent) => {
				checkForSwipeToClose(event, touchStartY, screenStartY)
				headerCartWrapper.removeEventListener(
					"touchend",
					touchEndHandler,
				)
			}
			headerCartWrapper.addEventListener("touchend", touchEndHandler)
		},
		{ passive: true },
	)
})

const clearRecipe = (): void => {
	if (itemCount.value === 0) return
	const confirmation = window.confirm(
		"Are You Sure You'd Like To Clear Your Recipe?",
	)
	if (confirmation === false) return
	recipeBuilderStore.clearWorkingRecipe()
	$log.info("[RecipeCart] Clear Cart")
	closeRecipeCart()
}

const __returned__ = { emit, recipeBuilderStore, itemCount, isCartOpen, clickListenerActive, toggleRecipeCartOpen, openHeaderCart, closeRecipeCart, checkForClickToClose, checkForSwipeToClose, clearRecipe, get $log() { return $log }, get useRecipeBuilderStore() { return useRecipeBuilderStore }, get storeToRefs() { return storeToRefs }, onMounted, ref, RecipeCart }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})