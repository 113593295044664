import { defineComponent as _defineComponent } from 'vue'
import type { ISearchItem } from "@shared/composables/useSearch"
import { CDNKey } from "@shared/useInjectionKeys"
import { computed, inject, watch } from "vue"

export default /*@__PURE__*/_defineComponent({
  __name: 'QuickAdd_ResultItem',
  props: {
    searchItem: {},
    isSelected: { type: Boolean, default: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const cdnpath = inject<string>(CDNKey)

const props = __props

watch(
	() => props.isSelected,
	() => {
		const el = document.querySelector(".selected")
		if (el) {
			el.scrollIntoView({
				behavior: "smooth",
				block: "center",
			})
		}
	},
	{ immediate: true },
)
const image = computed((): string => {
	if (props.searchItem.item.photos?.v50px) {
		return (
			cdnpath + "/images/products/" + props.searchItem.item.photos.v50px
		)
	} else if (props.searchItem.item.photos?.v250px) {
		return (
			cdnpath + "/images/products/" + props.searchItem.item.photos.v250px
		)
	} else {
		return cdnpath + "/images/products/gnome-thumb.jpg"
	}
})

const __returned__ = { cdnpath, props, image, get CDNKey() { return CDNKey }, computed, inject, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})