import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "recipeBuilderItem-image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "recipeBuilderItem-info" }
const _hoisted_4 = { class: "recipeBuilderItem-row info" }
const _hoisted_5 = { class: "recipeBuilderItem-cell itemname" }
const _hoisted_6 = { class: "recipeBuilderItem-cell" }
const _hoisted_7 = { class: "recipeBuilderItem-cell bulklogo" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "recipeBuilderItem-row quantity" }
const _hoisted_10 = { class: "recipeBuilderItem-cell short_description" }
const _hoisted_11 = { class: "recipeBuilderItem-cell" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = { class: "recipeBuilderItem-cell buttoncell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button_Medium = _resolveComponent("Button_Medium")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["recipeBuilderItem", $setup.itemClass]),
    onClick: $setup.showItemDetails
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", { src: $setup.image }, null, 8 /* PROPS */, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", null, _toDisplayString($props.item.name), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", null, "$" + _toDisplayString($props.item.price.toFixed(2)), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_7, [
          ($props.item.bulk)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: $setup.cdnpath + '/images/icons/bulk.png',
                title: "This Item Is Sold In Bulk in 0.01 Pound Increments"
              }, null, 8 /* PROPS */, _hoisted_8))
            : _createCommentVNode("v-if", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, _toDisplayString($props.item.description_short), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_11, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            pattern: "[0-9]*",
            inputmode: "decimal",
            placeholder: ($props.item.bulk ? '0.00' : '0'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.inputQuantity) = $event)),
            onKeydown: [
              _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => ($setup.convertOunces($event)), ["prevent"]), ["o"])),
              _cache[2] || (_cache[2] = ($event: any) => ($setup.preventInvalidDecimalKey($event)))
            ],
            onInput: $setup.updateSavedStatus,
            onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => ($setup.updateItemQuantity()), ["enter"])),
            onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
          }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_12), [
            [
              _vModelText,
              $setup.inputQuantity,
              void 0,
              { number: true }
            ]
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_Button_Medium, {
            class: _normalizeClass({disabled: !$setup.isAvailable}),
            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => ($setup.clickUpdateItem()), ["stop"])),
            tabindex: "-1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.addButtonText), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"])
        ])
      ])
    ])
  ], 2 /* CLASS */))
}