import Bowser from "bowser"
import { serializeError } from "serialize-error"
import type { App } from "vue"
import { publicAPI } from "./../api/api"

const getParsedUserAgent = () => {
	const userAgent = Bowser.getParser(window.navigator.userAgent)
	const browser =
		userAgent.getBrowserName() + ", " + userAgent.getBrowserVersion()
	const os = userAgent.getOSName() + " " + userAgent.getOSVersion()
	const platform = userAgent.getPlatformType()
	const parsedUserAgent = { browser, os, platform }
	return parsedUserAgent
}

//Vue Plugin
export const clientLogger = {
	install(app: App) {
		const trace = (message: string, ...objects: any) =>
			log("trace", message, objects)

		const info = (message: string, ...objects: any) =>
			log("info", message, objects)

		const warn = (message: string, ...objects: any) =>
			log("warn", message, objects)

		const error = (error: any, message: string, ...objects: any) => {
			err(error, message, objects)
		}

		const search = (
			location: string,
			searchTerm: string,
			nResults = 0,
			skuSelected: string | undefined = undefined,
		) => searchLog(location, searchTerm, nResults, skuSelected)

		app.config.globalProperties.$log = { trace, info, warn, error, search }
	},
}

//exposed for consumption outside Vue app
export const logger = {
	trace: function (message: string, ...objects: any) {
		log("trace", message, objects)
	},
	info: function (message: string, ...objects: any) {
		log("info", message, objects)
	},
	warn: function (message: string, ...objects: any) {
		log("warn", message, objects)
	},
	error: function (error: any, message: string, ...objects: any) {
		err(error, message, objects)
	},
	search: function (
		location: string,
		searchTerm: string,
		nResults: number,
		skuSelected?: string | undefined,
	) {
		searchLog(location, searchTerm, nResults, skuSelected)
	},
}

type LogLevel = "trace" | "info" | "warn" | "error"
const log = (level: LogLevel, message: string, objects?: any) => {
	try {
		const userAgent = getParsedUserAgent()
		if (userAgent.platform === "bot") return
		setTimeout(
			() =>
				publicAPI.post("log/client-log", {
					level,
					message,
					objects,
					...userAgent,
				}),
			0,
		)
	} catch (error) {
		console.error("[modules/clientlogger/log()", error)
	}
}

const searchLog = (
	location: string,
	searchTerm: string,
	nResults: number,
	skuSelected: string | undefined,
) => {
	try {
		setTimeout(
			() =>
				publicAPI.post("log/search-log", {
					location,
					searchTerm,
					nResults,
					skuSelected,
				}),
			0,
		)
	} catch (error) {
		console.error(error)
	}
}

const err = (error: any, message: string, objects: any) => {
	try {
		const userAgent = getParsedUserAgent()

		const serializedError = serializeError(error)
		publicAPI.post("log/client-error", {
			serializedError,
			message,
			objects,
			location,
			...userAgent,
		})
	} catch (error) {
		console.error(error)
	}
}
