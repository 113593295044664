import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { useModal } from "@Plugins/gbModal/gbModal"
import { useInventoryItemsSearch } from "@shared/composables/useSearch"
import { useQuery } from "@tanstack/vue-query"
import { useHead } from "@unhead/vue"
import {
	getRecipeBuilderItems,
	getRecipeBuilderSearchIndex,
} from "@website/api/inventory"
import { useOrderStore } from "@website/stores/order.js"
import { useRecipeBuilderStore } from "@website/stores/recipeBuilder"
import debounce from "lodash-es/debounce"
import { storeToRefs } from "pinia"
import { computed, nextTick, onMounted, ref, watch } from "vue"
import { event as GTagEvent } from "vue-gtag"
import { useRouter } from "vue-router"
import RecipeBuilder_Categories from "./RecipeBuilder_Categories.vue"
import RecipeBuilder_Item from "./RecipeBuilder_Item.vue"
import RecipeBuilder_SearchBox from "./RecipeBuilder_SearchBox.vue"
import RecipeName from "./RecipeName.gbModal.vue"

import { useDialog } from "@shared/plugins/gbDialog"
import type { IPublicInventoryItem } from "types/InventoryItem"
import type { IShopCategory } from "types/ShopCategory"
import RecipeBuilder_Item_Details from "./RecipeBuilder_Item_Details.gbModal.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeBuilder.page',
  props: {
    recipeID: { default: 0 }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

useHead({
	title: "Recipe Builder",
})

const router = useRouter()
const orderStore = useOrderStore()
const recipeBuilderStore = useRecipeBuilderStore()
const { showDialog } = useDialog()
const { workingRecipe: recipe, isNewRecipe } = storeToRefs(recipeBuilderStore)

const { data: recipeBuilderInventory, status: inventoryQueryStatus } = useQuery<
	IPublicInventoryItem[]
>({
	queryKey: ["recipeBuilderInventoryItems"],
	queryFn: getRecipeBuilderItems,
})
const isSearchLoading = computed<boolean>(() => {
	return inventoryQueryStatus.value !== "success" || ready.value !== true
})
const { data: recipeBuilderSearchIndex } = useQuery({
	queryKey: ["recipeBuilderSearchIndex"],
	queryFn: getRecipeBuilderSearchIndex,
})

const filterCategory = ref<IShopCategory | undefined>(undefined)
watch(filterCategory, () => {
	searchTerm.value = ""
})

const offPage = ref(false)

onMounted(() => {
	if (props.recipeID === 0) {
		setUpNewReceipe()
	} else {
		setUpWithSavedReceipe()
	}
})

const setUpNewReceipe = (): void => {
	const nextRecipeID = orderStore.getNextRecipeID()
	recipe.value.name = `Recipe ${nextRecipeID}`
	useModal.show("newRecipeDialog", {
		recipeName: recipe.value.name,
	})
	$log.info("New Recipe Started", {
		recipeID: recipe.value.id,
		name: recipe.value.name,
	})
	GTagEvent("new_recipe", {
		event_category: "recipe_builder",
	})
}

const setUpWithSavedReceipe = (): void => {
	const result = recipeBuilderStore.getRecipeCopyByID(props.recipeID)
	if (result) recipe.value = result
	else {
		showDialog(
			"Recipe Not Found",
			"The recipe you are looking for could not be found.",
		)
		$log.error(
			{ recipeID: props.recipeID },
			"Recipe Builder Recipe Not Found",
		)
	}
}

const searchTerm = ref("")
const { results, ready } = useInventoryItemsSearch(
	recipeBuilderInventory,
	recipeBuilderSearchIndex,
	searchTerm,
)

const debouncedSearchLogger = debounce(
	(searchTerm: string, resultsCount: number) => {
		$log.info("Recipe Builder Search", {
			recipeID: recipe.value.id,
			recipeName: recipe.value.name,
			searchTerm,
			resultsCount,
		})
	},
	500,
)
watch(searchTerm, (newSearchTerm) => {
	recipeBuilderStore.searchTermForLogging = newSearchTerm
	if (newSearchTerm.length === 0) return
	debouncedSearchLogger(newSearchTerm, results.value.length)
})

const filteredInventory = computed((): IPublicInventoryItem[] => {
	let items: IPublicInventoryItem[] = []
	if (results.value.length > 0) {
		items = results.value.map((result) => result.item)
	} else if (!filterCategory.value || !recipeBuilderInventory.value) {
		items = []
	} else {
		const filterCategoryID = filterCategory.value.id
		items = recipeBuilderInventory.value.filter(
			(item) =>
				item.canonical_category === filterCategoryID ||
				item.categories.includes(filterCategoryID),
		)
		items.sort((a, b) => {
			if (a.name > b.name) {
				return 1
			} else if (a.name < b.name) {
				return -1
			} else {
				return 0
			}
		})
	}
	void scrollToItemsTop()
	return items
})

const isSmallScreen = computed((): boolean => {
	const breakWidth = 600
	if (window.innerWidth < breakWidth) {
		return true
	} else {
		return false
	}
})

const scrollToItemsTop = async (): Promise<void> => {
	await nextTick()
	const itemList = document.getElementById("RecipeBuilder-Items")
	if (itemList) itemList.scrollTop = 0

	//const itemsTop = document.getElementById("itemstop")
	//const y = itemsTop?.getBoundingClientRect().y || 0
	//const modalOverlay = document.getElementsByClassName("gb-modal-overlay")[0]
	//modalOverlay.scrollBy(0, y)
}

const handleSaveNewName = (name: string): void => {
	const newName = name
	if (newName && newName.length > 0) {
		$log.info("Named Recipe", {
			recipeID: recipe.value.id,
			oldName: recipe.value.name,
			newName: newName,
		})
		recipe.value.name = newName
	}
}

const handleCancelNewRecipe = (): void => {
	$log.info("Recipe Canceled", {
		recipeID: recipe.value.id,
		name: recipe.value.name,
	})
	recipeBuilderStore.resetWorkingRecipe()
	void router.push("/shop")
}

const __returned__ = { props, router, orderStore, recipeBuilderStore, showDialog, recipe, isNewRecipe, recipeBuilderInventory, inventoryQueryStatus, isSearchLoading, recipeBuilderSearchIndex, filterCategory, offPage, setUpNewReceipe, setUpWithSavedReceipe, searchTerm, results, ready, debouncedSearchLogger, filteredInventory, isSmallScreen, scrollToItemsTop, handleSaveNewName, handleCancelNewRecipe, get $log() { return $log }, get useModal() { return useModal }, get useInventoryItemsSearch() { return useInventoryItemsSearch }, get useQuery() { return useQuery }, get useHead() { return useHead }, get getRecipeBuilderItems() { return getRecipeBuilderItems }, get getRecipeBuilderSearchIndex() { return getRecipeBuilderSearchIndex }, get useOrderStore() { return useOrderStore }, get useRecipeBuilderStore() { return useRecipeBuilderStore }, get debounce() { return debounce }, get storeToRefs() { return storeToRefs }, computed, nextTick, onMounted, ref, watch, get GTagEvent() { return GTagEvent }, get useRouter() { return useRouter }, RecipeBuilder_Categories, RecipeBuilder_Item, RecipeBuilder_SearchBox, RecipeName, get useDialog() { return useDialog }, RecipeBuilder_Item_Details }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})