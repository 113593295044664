import { defineComponent as _defineComponent } from 'vue'
import { preventInvalidDecimalKey } from "@Functions/fnInput"
import { logger as $log } from "@Plugins/clientLogger"
import { useOrderStore } from "@website/stores/order.js"
import { computed, inject, watch } from "vue"

//types
import { CDNKey } from "@shared/useInjectionKeys"
import type { IShopCartItem } from "types/ShopCartItem"
import type { IShopCategory } from "types/ShopCategory"


export default /*@__PURE__*/_defineComponent({
  __name: 'cart-item',
  props: {
    item: {},
    canonicalCategory: {},
    editmode: { type: Boolean, default: false }
  },
  emits: ["closeHeaderCart"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const cdnpath = inject<string>(CDNKey)

const props = __props

const emit = __emit
const orderStore = useOrderStore()

const quantityAvailable = computed(() => {
	return (
		props.item.inventory -
		orderStore.getQuantityOrderedElsewhere(props.item.sku)
	)
})

const quantity = computed({
	get(): number {
		return props.item.quantity
	},
	set(newQuantity: number): void {
		newQuantity = checkQuantity(newQuantity)
		orderStore.updateItemInCart(props.item, newQuantity)
		$log.info("updateCartItem", {
			sku: props.item.sku,
			name: props.item.name,
			quantity: newQuantity,
		})
	},
})

const productThumb = computed((): string => {
	if (props.item?.photos?.v50px) {
		return cdnpath + "/images/products/" + props.item.photos.v50px
	} else {
		return cdnpath + "/images/products/gnome-thumb.jpg"
	}
})

const href = computed((): string => {
	let href = "/shop/"
	href += props.canonicalCategory.url + "/"
	href += "items/" + props.item.url + "/" + props.item.sku
	return href
})

const checkQuantity = (newQuantity: number): number => {
	if (props.item.kit === false && newQuantity > quantityAvailable.value) {
		//If No Special Order, Reject
		const roundedQuantityAvailable = Math.max(
			Math.round(quantityAvailable.value * 100) / 100,
			0,
		)
		let availablityLanguage = ""
		if (roundedQuantityAvailable === 0)
			availablityLanguage = "Sorry, We Are Currently Out Of Stock."
		else
			availablityLanguage =
				"Sorry, We Only Have " +
				roundedQuantityAvailable +
				" Available At This Time."
		if (!props.item.allow_backorder) {
			window.alert(availablityLanguage)
			$log.info("Cart: Insufficient Inventory", {
				sku: props.item.sku,
				name: props.item.name,
				inputQuantity: newQuantity,
				inventory: props.item.inventory,
			})
			return quantityAvailable.value
		} else if (props.item.allow_backorder) {
			const allowBackOrder = window.confirm(
				availablityLanguage +
					" Would You Like To Back Order This Item?  Contact or Email For An Estimate On Time Until Back In Stock.",
			)
			if (!allowBackOrder) {
				return quantityAvailable.value
			} else {
				return newQuantity
			}
		}
	}

	if (!props.item.bulk && Math.round(newQuantity) != newQuantity) {
		alert("This Item Is Not Sold in Bulk, Please Enter a Whole Number")
		newQuantity = Math.round(newQuantity)
		return newQuantity
	}
	return newQuantity
}

const removeCartItem = (): void => {
	orderStore.removeItemInCart(props.item.sku)
	$log.info("removeItemFromCart", {
		sku: props.item.sku,
		name: props.item.name,
	})
}

const closeHeaderCart = (): void => {
	emit("closeHeaderCart")
}

watch(
	() => props.item.quantity,
	() => {
		if (props.item.quantity === 0) {
			removeCartItem()
		}
	},
)

const __returned__ = { cdnpath, props, emit, orderStore, quantityAvailable, quantity, productThumb, href, checkQuantity, removeCartItem, closeHeaderCart, get preventInvalidDecimalKey() { return preventInvalidDecimalKey }, get $log() { return $log }, get useOrderStore() { return useOrderStore }, computed, inject, watch, get CDNKey() { return CDNKey } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})