import { defineComponent as _defineComponent } from 'vue'
import type { IPublicInventoryItem } from "types/InventoryItem"
import { computed } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ItemView_DescriptionLong',
  props: {
    description_long: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props

const wrappedLongDescription = computed((): string => {
	return "<div>" + props.description_long + "</div>"
})

const __returned__ = { props, wrappedLongDescription, computed }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})