import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "drawer" }
const _hoisted_2 = { class: "drawer-header-content" }
const _hoisted_3 = {
  key: 0,
  class: "drawer-header-toggle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.showHeader)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "drawer-header",
          onClick: $setup.toggleDrawerOpen
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h3", null, "  " + _toDisplayString($props.header), 1 /* TEXT */)
          ]),
          ($setup.drawerToggleActive)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                ($setup.isDrawerOpen)
                  ? (_openBlock(), _createBlock($setup["arrowUp"], { key: 0 }))
                  : _createCommentVNode("v-if", true),
                (!$setup.isDrawerOpen)
                  ? (_openBlock(), _createBlock($setup["arrowDown"], { key: 1 }))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(["drawer-body", [{open: $setup.isDrawerOpen},{nopadding: $props.noPadding},$setup.classesArray]])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2 /* CLASS */)
  ]))
}