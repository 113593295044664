import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "gbDialog"
}
const _hoisted_2 = { class: "modalMask" }
const _hoisted_3 = {
  class: "gbDialog-container",
  ref: "modalContainer"
}
const _hoisted_4 = { class: "gbDialog-header" }
const _hoisted_5 = { class: "gbDialog-title" }
const _hoisted_6 = { class: "gbDialog-body" }
const _hoisted_7 = {
  key: 0,
  class: "gbDialog-message"
}
const _hoisted_8 = { class: "gbDialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.dialogOptions.isOpen)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString($setup.dialogOptions.title), 1 /* TEXT */)
            ]),
            _createElementVNode("div", _hoisted_6, [
              ($setup.dialogOptions.message)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString($setup.dialogOptions.message), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_8, [
              ($setup.dialogOptions.showButton)
                ? (_openBlock(), _createBlock($setup["ButtonBar_Center"], { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode($setup["Button_Medium"], {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.closeDialog()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($setup.dialogOptions.buttonText), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ])
          ], 512 /* NEED_PATCH */)
        ])
      ]))
    : _createCommentVNode("v-if", true)
}