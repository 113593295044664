import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["height", "width", "fill"]


export default /*@__PURE__*/_defineComponent({
  __name: 'arrowDown',
  props: {
    height: { default: "36px" },
    width: { default: "36px" },
    fill: { default: "#000" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    height: _ctx.height,
    width: _ctx.width,
    fill: _ctx.fill,
    viewBox: "0 0 24 24"
  }, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M12 16l-6-6h12l-6 6z" }, null, -1 /* HOISTED */)
  ]), 8 /* PROPS */, _hoisted_1))
}
}

})