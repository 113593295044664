import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "AppContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_gbModalProcessing = _resolveComponent("gbModalProcessing")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, { name: "appHeader" }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view),
      _createVNode(_component_router_view, { name: "appFooter" }),
      _createVNode($setup["gbDialog"])
    ]),
    _createVNode(_component_gbModalProcessing)
  ], 64 /* STABLE_FRAGMENT */))
}