import { defineComponent as _defineComponent } from 'vue'
import Button_Medium from "@shared/components/buttons/Button_Medium.vue"
import { useDialog } from "@shared/plugins/gbDialog"
import { sendNewsletterConfirmationEmail } from "@website/api/customer"
import { ref } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'NewsletterSignup',
  setup(__props, { expose: __expose }) {
  __expose();

const validationError = ref("")
const email = ref("")
const { showDialog } = useDialog()

const reset = () => {
	validationError.value = ""
	email.value = ""
}
const signup = async () => {
	const response = await sendNewsletterConfirmationEmail(email.value)
	reset()
	if (response.status === 201) {
		showDialog(
			"Thanks for signing up!",
			"Please check your email to confirm your subscription.",
		)
	} else if (response.status === 200) {
		showDialog("You're already subscribed to our newsletter.")
	} else if (response.validationError) {
		showDialog("Please enter a valid email address.")
	} else {
		showDialog("An error occurred. Please try again later.")
	}
}

const __returned__ = { validationError, email, showDialog, reset, signup, Button_Medium, get useDialog() { return useDialog }, get sendNewsletterConfirmationEmail() { return sendNewsletterConfirmationEmail }, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})