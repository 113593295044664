import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { CDNKey } from "@shared/useInjectionKeys"
import { useGetShopCategories } from "@website/api/shopCategories"
import { useOrderStore } from "@website/stores/order.js"
import type { IRecipe } from "types/Recipe"
import type { IShopCategory } from "types/ShopCategory"
import { inject } from "vue"
import { useRouter } from "vue-router"
import CartItem from "./cart-item.vue"
import CartRecipe from "./cart-recipe.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'cart',
  props: {
    editmode: { type: Boolean, default: false }
  },
  emits: ["closeHeaderCart"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const cdnpath = inject<string>(CDNKey)


const emit = __emit

const orderStore = useOrderStore()
const router = useRouter()

const { isSuccess: loaded, shopCategories } = useGetShopCategories()

const getShopCategoryByID = (categoryID: number): IShopCategory => {
	const category = shopCategories.value.find(
		(category) => category.id === categoryID,
	)
	if (category) return category
	else throw new Error("Category Not Found")
}

const handleEditRecipe = (recipe: IRecipe): void => {
	closeHeaderCart()
	router
		.push({
			name: "Recipe Builder",
			params: { recipeID: recipe.id.toString() },
		})
		.catch((error) => {
			if (error.name !== "NavigationDuplicated") throw error
		})
	$log.info("Edit Recipe", {
		recipeID: recipe.id,
		name: recipe.name,
	})
}

const closeHeaderCart = (): void => {
	emit("closeHeaderCart")
}

const __returned__ = { cdnpath, emit, orderStore, router, loaded, shopCategories, getShopCategoryByID, handleEditRecipe, closeHeaderCart, get $log() { return $log }, get CDNKey() { return CDNKey }, get useGetShopCategories() { return useGetShopCategories }, get useOrderStore() { return useOrderStore }, inject, get useRouter() { return useRouter }, CartItem, CartRecipe }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})