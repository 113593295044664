import { defineComponent as _defineComponent } from 'vue'
import { CDNKey } from "@shared/useInjectionKeys"
import type { IPublicInventoryItem } from "types/InventoryItem"
import { computed, inject } from "vue"

export default /*@__PURE__*/_defineComponent({
  __name: 'ItemView_ImageBox',
  props: {
    photos: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const cdnpath = inject<string>(CDNKey)

const props = __props

const image = computed((): string => {
	if (props.photos?.v550px) {
		return cdnpath + "/images/products/" + props.photos.v550px
	} else {
		return (
			cdnpath + "/images/logos/gnome-brew-logo-with-text-full-color.png"
		)
	}
})

const __returned__ = { cdnpath, props, image, get CDNKey() { return CDNKey }, computed, inject }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})