import { App, InjectionKey } from "vue"

export { CDNKey, S3BucketKey, useInjectionKeys }

const CDNKey = Symbol("CDNKey") as InjectionKey<string>
const S3BucketKey = Symbol() as InjectionKey<string>

const useInjectionKeys = (app: App) => {
	app.provide(CDNKey, "https://dyf3vyio16s6o.cloudfront.net")
	app.provide(S3BucketKey, "https://gnomebrew-cdn.s3.us-east-2.amazonaws.com")
}
