import { defineComponent as _defineComponent } from 'vue'
import { CDNKey } from "@shared/useInjectionKeys"
import { inject } from "vue"
import NewsletterSignup from "./newsletter/NewsletterSignup.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'appFooter',
  setup(__props, { expose: __expose }) {
  __expose();

const cdnpath = inject<string>(CDNKey)

const __returned__ = { cdnpath, get CDNKey() { return CDNKey }, inject, NewsletterSignup }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})