import { defineComponent as _defineComponent } from 'vue'
import { gbDialog } from "@shared/plugins/gbDialog"
import { useQueryClient } from "@tanstack/vue-query"
import { useHead } from "@unhead/vue"

const minutes = 1000 * 60
//const hour = minutes * 60

export default /*@__PURE__*/_defineComponent({
  __name: 'website',
  setup(__props, { expose: __expose }) {
  __expose();

useHead({
	titleTemplate: (title) => {
		if (title && title !== "Homepage") {
			return `${title}  | Gnome Brew - Your Home For Homebrew`
		} else {
			return "Gnome Brew - Your Home For Homebrew"
		}
	},
})

const queryClient = useQueryClient()
queryClient.setQueryDefaults(["shopCategories"], {
	staleTime: 1 * minutes, //default 0
	gcTime: 5 * minutes, //default
})
queryClient.setQueryDefaults(["inventoryItems"], {
	staleTime: 0, //default
	gcTime: 5 * minutes, //default
})
queryClient.setQueryDefaults(["upcomingEvents"], {
	staleTime: 0, //default
	gcTime: 5 * minutes, //default
})
queryClient.setQueryDefaults(["profile"], {
	staleTime: 0, //default
	gcTime: 5 * minutes, //default
})

const __returned__ = { minutes, queryClient, get gbDialog() { return gbDialog }, get useQueryClient() { return useQueryClient }, get useHead() { return useHead } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})