import {
	getErrorByType,
	getObjectByType,
	hasJRPErrorType,
} from "@shared/api/JRPHelper"
import { ServerError } from "@shared/errors"
import api from "./api"

//typesimport type { IValidationError } from "types/APIResponses"
import { IRequestError, IValidationError } from "types/APIResponses"
import type { ICustomer, ICustomerProfile } from "types/Customer"

const customerPath = "customers/"
export {
	getCustomerProfileByID,
	getCustomerProfileByToken,
	sendNewsletterConfirmationEmail,
	subscribeNewsletterByToken,
	unsubscribeNewsletterByToken,
}

/** @deprecated use getCustomerProfileByToken */
const getCustomerProfileByID = async (
	customerID: ICustomer["id"],
): Promise<ICustomerProfile> => {
	try {
		const { data } = await api.get(
			customerPath + "profile/id/" + customerID,
			{
				validateStatus: (status) => {
					return status === 200
				},
			},
		)
		const customerProfile = getObjectByType<ICustomerProfile>(
			data,
			"CustomerProfile",
		)
		if (customerProfile === null) {
			throw new ServerError("CustomerProfile not returned from server")
		} else {
			return customerProfile
		}
	} catch (error) {
		return Promise.reject(error)
	}
}

const getCustomerProfileByToken = async (
	token: string,
): Promise<ICustomerProfile> => {
	try {
		const { data } = await api.get(
			customerPath + "profile/token/" + token,
			{
				validateStatus: (status) => {
					return status === 200
				},
			},
		)
		const customerProfile = getObjectByType<ICustomerProfile>(
			data,
			"CustomerProfile",
		)
		if (customerProfile === null) {
			throw new ServerError("CustomerProfile not returned from server")
		} else {
			return customerProfile
		}
	} catch (error) {
		return Promise.reject(error)
	}
}

const sendNewsletterConfirmationEmail = async (
	email: string,
): Promise<{
	status: number
	requestError?: IRequestError
	validationError?: IValidationError
}> => {
	try {
		const { data, status } = await api.post(
			customerPath + "send-newsletter-confirmation-email/" + email,
			{},
			{
				validateStatus: (status) => [200, 201, 400].includes(status),
			},
		)
		if (status === 400) {
			if (hasJRPErrorType(data, "RequestError")) {
				const requestError = getErrorByType<IRequestError>(
					data,
					"RequestError",
				)
				return { status, requestError }
			} else if (hasJRPErrorType(data, "ValidationError")) {
				const validationError = getErrorByType<IValidationError>(
					data,
					"ValidationError",
				)
				return { status, validationError }
			}
		} else return { status }
	} catch (error) {
		console.error("error", error)
		return Promise.reject(error)
	}
	return Promise.reject("Unhandled error")
}

const subscribeNewsletterByToken = async (token: string): Promise<void> => {
	try {
		await api.post(
			customerPath + "subscribe-newsletter/token/" + token,
			{},
			{
				validateStatus: (status) => status === 201,
			},
		)
	} catch (error) {
		return Promise.reject(error)
	}
}

const unsubscribeNewsletterByToken = async (token: string): Promise<void> => {
	try {
		await api.post(
			customerPath + "unsubscribe-newsletter/token/" + token,
			{},
			{
				validateStatus: (status) => status === 204,
			},
		)
	} catch (error) {
		return Promise.reject(error)
	}
}
