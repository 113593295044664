import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "itemview-imagebox" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "center",
          src: $setup.image
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true)
  ]))
}