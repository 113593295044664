import appHeader from "@website/components/header/appHeader.vue"

const Certificates = () =>
	import(
		/* webpackChunkName: "app-route-certificates" */ "../components/certificates/certificates.vue"
	)
const Certificates_SelectType = () =>
	import(
		/* webpackChunkName: "app-route-certificates" */ "../components/certificates/selecttype.page.vue"
	)
const Certificates_Info_CashCertificate = () =>
	import(
		/* webpackChunkName: "app-route-certificates" */ "../components/certificates/info-cashcertificate.page.vue"
	)
const Certificates_Info_ClassCertificate = () =>
	import(
		/* webpackChunkName: "app-route-certificates" */ "../components/certificates/info-classcertificate.page.vue"
	)
const Certificates_Customer = () =>
	import(
		/* webpackChunkName: "app-route-certificates" */ "../components/certificates/customer.page.vue"
	)
const Certificates_Payment = () =>
	import(
		/* webpackChunkName: "app-route-certificates" */ "../components/certificates/payment.page.vue"
	)
const Certificates_Confirmation = () =>
	import(
		/* webpackChunkName: "app-route-certificates" */ "../components/certificates/confirmation.page.vue"
	)

const routes = [
	{
		path: "/certificates",
		name: "Certificates",
		components: {
			appHeader,
			default: Certificates,
		},
		children: [
			{
				path: "/certificates",
				name: "Certificates_SelectType",
				components: { default: Certificates_SelectType },
			},
			{
				path: "/certificates/info-cashcertificate",
				name: "Certificates_Info_CashCertificate",
				components: { default: Certificates_Info_CashCertificate },
				beforeEnter: (to, from, next) => {
					if (
						from.name &&
						[
							"Certificates_SelectType",
							"Certificates_Customer",
						].includes(from.name as string)
					) {
						next()
					} else {
						next({ name: "Certificates_SelectType" })
					}
				},
			},
			{
				path: "/certificates/info-classcertificate",
				name: "Certificates_Info_ClassCertificate",
				components: { default: Certificates_Info_ClassCertificate },
				beforeEnter: (to, from, next) => {
					if (
						from.name &&
						[
							"Certificates_SelectType",
							"Certificates_Customer",
						].includes(from.name as string)
					) {
						next()
					} else {
						next({ name: "Certificates_SelectType" })
					}
				},
			},
			{
				path: "/certificates/customer",
				name: "Certificates_Customer",
				components: { default: Certificates_Customer },
				beforeEnter: (to, from, next) => {
					if (
						from.name &&
						[
							"Certificates_Info_CashCertificate",
							"Certificates_Info_ClassCertificate",
							"Certificates_Payment",
						].includes(from.name as string)
					) {
						next()
					} else {
						next({ name: "Certificates_SelectType" })
					}
				},
			},
			{
				path: "/certificates/payment",
				name: "Certificates_Payment",
				components: { default: Certificates_Payment },
				beforeEnter: (to, from, next) => {
					if (
						from.name &&
						[
							"Certificates_Customer",
							"Certificates_Payment",
						].includes(from.name as string)
					) {
						next()
					} else {
						next({ name: "Certificates_Customer" })
					}
				},
			},
			{
				path: "/certificates/confirmation/:certificateID/:receiptNumber/:customerID",
				name: "Certificates_Confirmation",
				components: {
					default: Certificates_Confirmation,
				},
				beforeEnter: (to, from, next) => {
					if (from.name && from.name === "Certificates_Payment") {
						next()
					} else {
						next({ name: "Certificates_Payment" })
					}
				},
			},
			{
				path: "/certificates/:pathMatch(.*)*",
				redirect: "/certificates",
			},
		],
	},
]

export default routes
