import axios from "axios"
import { ref } from "vue"
export { publicAPI, useGetLocalDeliverySettings }
const version = require("@/package.json").version
const baseURL = "https://api." + window.location.host + "/api/v0/public/"
const publicAPI = axios.create({
	baseURL,
	headers: {
		"app-version": version,
	},
	withCredentials: true,
})

const localdelivery = {
	fee: 7.99,
	zipcodes: [
		"60610",
		"60613",
		"60614",
		"60618",
		"60622",
		"60625",
		"60626",
		"60640",
		"60642",
		"60647",
		"60657",
		"60659",
		"60660",
	],
}
const useGetLocalDeliverySettings = () => {
	const fee = ref(localdelivery.fee)
	const zipcodes = ref(localdelivery.zipcodes)
	return { fee, zipcodes }
}
