import { defineComponent as _defineComponent } from 'vue'
import Button_Medium from "@shared/components/buttons/Button_Medium.vue"
import ButtonBar_Center from "@shared/components/buttons/ButtonBar_Center.vue"
import { useModal } from "@shared/plugins/gbModal/gbModal"
import cloneDeep from "lodash/cloneDeep"
import type { IPublicInventoryItem } from "types/InventoryItem"
import { ref } from "vue"
import ItemView_DescriptionLong from "../shop/ItemView/ItemView_DescriptionLong.vue"
import ItemView_DescriptionShort from "../shop/ItemView/ItemView_DescriptionShort.vue"
import ItemView_ImageBox from "../shop/ItemView/ItemView_ImageBox.vue"
import ItemView_ItemContainer from "../shop/ItemView/ItemView_ItemContainer.vue"
import ItemView_ItemName from "../shop/ItemView/ItemView_ItemName.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'RecipeBuilder_Item_Details.gbModal',
  setup(__props, { expose: __expose }) {
  __expose();

const item = ref<IPublicInventoryItem | null>(null)

const beforeOpen = (params): void => {
	item.value = cloneDeep(params.item)
}

const close = (): void => {
	useModal.hide("RecipeBuilder_Item_Details")
	item.value = null
}

const __returned__ = { item, beforeOpen, close, Button_Medium, ButtonBar_Center, get useModal() { return useModal }, get cloneDeep() { return cloneDeep }, ref, ItemView_DescriptionLong, ItemView_DescriptionShort, ItemView_ImageBox, ItemView_ItemContainer, ItemView_ItemName }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})