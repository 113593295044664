import { defineComponent as _defineComponent } from 'vue'
import ButtonBar_Center from "@shared/components/buttons/ButtonBar_Center.vue"
import Button_Medium from "@shared/components/buttons/Button_Medium.vue"
import { onClickOutside } from "@vueuse/core"
import { useTemplateRef } from "vue"
import { useDialog } from "./gbDialog"


export default /*@__PURE__*/_defineComponent({
  __name: 'gbDialog',
  setup(__props, { expose: __expose }) {
  __expose();

const { closeDialog, dialogOptions } = useDialog()

const modalContainer = useTemplateRef("modalContainer")
onClickOutside(modalContainer, () => closeDialog())

const __returned__ = { closeDialog, dialogOptions, modalContainer, ButtonBar_Center, Button_Medium, get onClickOutside() { return onClickOutside }, useTemplateRef, get useDialog() { return useDialog } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})